import LogoText from "../assets/images/logoText.png";
import FooterLink from "./generic/FooterLink";
import Phone from "../assets/icons/phone.svg";
import Envelop from "../assets/icons/envelop.svg";
import LinkedIn from "../assets/icons/linkedin.svg";

const MainFooter = () => {
  return (
    <footer className="flex items-start justify-start flex-wrap gap-16 px-16 max-md:px-4 pt-6 pb-24 bg-primary text-white">
      <figure className="h-8 w-auto max-md:hidden">
        <img src={LogoText} alt="logistika urbana logo" className="h-full w-auto" />
      </figure>
      <div className="flex flex-col items-start justify-start gap-4">
        <p className="font-semibold text-lg">Contacto</p>
        <FooterLink href="tel:+525520041035" icon={Phone} text="(+52) 55 2004 1035" />
        <FooterLink href="mailto:contact@logistikaurbana.com" icon={Envelop} text="contact@logistikaurbana.com" />
      </div>
      <div className="flex flex-col items-start justify-start gap-4">
        <p className="font-semibold text-lg">Siguenos</p>
        <FooterLink href="https://mx.linkedin.com/company/logistika-urbana" icon={LinkedIn} text="Logistika Urbana" />
      </div>
    </footer>
  );
}
 
export default MainFooter;