import LandingPage from "./pages/LandingPage";
import "./styles.css";

function App() {
  return (
    <LandingPage />
  );
}

export default App;
