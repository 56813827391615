const Card = ({ image, title = 'title', description = 'Description' }) => {
  return (
    <article className="flex flex-col items-center justify-between w-56 h-72 p-4 rounded-2xl shadow-[0_0_10px_2px_rgba(0,0,0,0.4)] hover:shadow-[0_0_6px_4px_rgba(0,0,0,0.4)]">
      <figure className="w-20 h-20">
        <img src={image} alt="lk" />
      </figure>
      <h4 className="font-bold text-2xl text-primary capitalize">{title}</h4>
      <p className="font-medium text-sm text-center">{description}</p>
    </article>
  );
}
 
export default Card;