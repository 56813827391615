import LogoText from "../assets/images/logoText.png";

const MainHeader = ({ bgTransparent = false, className }) => {
  const invertStyles = { filter: "invert(1) brightness(0)" }

  return (
    <header className={`flex items-center justify-between max-md:justify-center gap-8 h-20 px-16 py-2 max-md:px-4 ${!bgTransparent && 'bg-white'} ${className}`}>
      <figure className="h-8 w-auto max-md:hidden">
        <img src={LogoText} alt="logistika urbana logo" className="h-full w-auto" style={!bgTransparent ? invertStyles : {}} />
      </figure>
      <nav className="flex items-center justify-end gap-4">
        <a href="#home" className={`font-semibold text-sm ${bgTransparent && 'text-white'} hover:text-${bgTransparent ? 'black' : 'primary'}`}>Inicio</a>
        <a href="#aboutus" className={`font-semibold text-sm ${bgTransparent && 'text-white'} hover:text-${bgTransparent ? 'black' : 'primary'}`}>Acerca de</a>
        <a href="#services" className={`font-semibold text-sm ${bgTransparent && 'text-white'} hover:text-${bgTransparent ? 'black' : 'primary'}`}>Servicios</a>
        <a
          href="https://admin.logistikaurbana.com/"
          className={`px-2 p-1 rounded-lg font-semibold text-sm ${bgTransparent ? 'bg-white text-primary' : 'bg-primary text-white'}`}
        >
          Login
        </a>
      </nav>
    </header>
  );
}
 
export default MainHeader;