const FooterLink = ({ icon, href, text }) => {
  return (
    <a
      href={href}
      className="flex items-end justify-start gap-2 text-sm hover:underline"
    >
      {
        icon &&
          <figure className="flex items-center justify-center w-4 h-4">
            <img
              src={icon}
              alt="Icon that represents the address to which this link is directed"
              className="h-full w-auto"
            />
          </figure>
      }
      {text}
    </a>
  );
}
 
export default FooterLink;