import { useEffect, useState } from "react";
import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import Card from "../../components/generic/Card";
import DeliveryIcon from "../../assets/images/delivery.png";
import OnlineStore from "../../assets/images/online_store.png";
import Store from "../../assets/images/store.png";
import LOGOIMAGE from "../../assets/images/LandingImage.png";

const LandingPage = () => {
  const [isFloatingHeaderVisible, setIsFloatingHeaderVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFloatingHeaderVisible(true)
      } else {
        setIsFloatingHeaderVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <MainHeader className={`fixed -top-20 left-0 w-full z-30 ${isFloatingHeaderVisible && 'translate-y-20'} transition-transform duration-300`}/>
      <main>
        <section id="home" className="relative h-dvh min-h-[32rem] overflow-hidden bg-primary">
          <figure className="absolute top-[50%] max-md:top-[40%] -left-2 -translate-y-1/2 flex items-center justify-center w-full h-auto max-h-[55rem] object-cover">
            <img src={LOGOIMAGE} alt="bg" className="max-w-none w-[150%] max-lg:w-[180%] max-md:w-[220%]" />
          </figure>
          <MainHeader bgTransparent className="relative z-10" />
          <p className="absolute left-2/4 -translate-x-2/4 top-[60%] font-semibold text-xl text-center text-white max-w-[38rem] w-full px-4 z-10 max-md:px-12">Transformando la comercialización de productos al menudeo en regiones con alta densidad de población</p>
        </section>
        <section id="aboutus" className="flex flex-col items-center justify-start gap-8 pt-24 pb-8 px-8">
          <h2 className="font-bold text-3xl text-primary">Acerca de</h2>
          <div className="flex flex-col items-center justify-center gap-8">
            <div className="flex items-start justify-center gap-8 flex-wrap">
              <p className="max-w-80 text-balance"><span className="font-bold text-xl text-primary text-left">Logistika Urbana</span> es una empresa dedicada a la venta y distribución de una amplia gama de productos de alto consumo en espacios urbanos asegurando una cobertura eficiente y puntual.</p>
              <p className="max-w-80 text-balance">Transformamos la comercialización y distribución de una amplia gama de diferentes productos por medio de nuestros servicios de ventas en línea, ventas presenciales y entregas a domicilio</p>
            </div>
          </div>
        </section>
        <section id="services" className="flex flex-col items-center justify-start gap-8 pt-24 pb-16 px-8">
          <h2 className="font-bold text-3xl text-primary">Servicios</h2>
          <p className="max-w-[50rem] text-center text-sm">En Logistika Urbana, te ofrecemos diversas formas de adquirir nuestros productos, adaptándonos a tus necesidades y preferencias.</p>
          <div className="flex items-center justify-center gap-8 flex-wrap">
            <Card
              image={DeliveryIcon}
              title="A Domicilio"
              description=" Distribución de todos los productos que necesites  hasta la puerta de tu casa."
            />
            <Card
              image={OnlineStore}
              title="En Línea"
              description="Compras en línea, en cualquier momento, desde cualquier lugar."
            />
            <Card
              image={Store}
              title="Presencial"
              description="Puntos de venta para compras presenciales."
            />
          </div>
        </section>
      </main>
      <MainFooter />
    </>
  );
}
 
export default LandingPage;